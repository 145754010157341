.product-control{
    display: flex;
    align-items: center;
}
.component-name{
    flex-grow: 1;
    font-size: 1.8rem;
    padding-left: 10px;
}
.product-control .btn{
    margin-left: 7px;
}
.control-file-name input{
    font-size: 0.9rem;
    padding: 18px 10px;
}
.control-branch{
    font-size: 0.9rem;
}
