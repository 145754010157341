.sort-list{
    list-style: none;
    margin: 0;
    padding-left: 20px;
    max-width: 300px;

}

.sort-list li, .sort-element{
    padding: 10px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    list-style: none;
    font-size: 15px;
    background: #ffffff;
}