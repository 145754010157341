.widget-container{
    padding: 0 20px;
}
.widget-item{
    margin: 5px 0;
}
.widget-item .header{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}
.widget-item .users-link{
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.widget-item .users-name{
    flex-basis: 300px;
    font-weight: bold;
    font-size: 15px;
    margin-right:10px;
}

.widget-item .url{
    flex-basis: 400px;
    margin-right:10px;
}

.widget-container .warning{
    color: red;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
.widget-container .copy {
    color: #0f9119;
    margin-left: 10px;
}
.widget-container .copy.non-select{
    display: none;
}

.widget-container .copy.select{
    display: block;
}