.login-item .image{
    width: 100px;
    height: 100px;

    border-radius: 50%;
}

.login-item{
    display: flex;

}
.login-item .user-info{
    padding: 5px 40px;
    flex-grow: 1;
}
.login-item .login{
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    line-height: 120%;
    height: 50px;
}
.login-item .login .page{
    font-size: 16px;
    font-weight: normal;
    color: #807c7c;
    margin-bottom: 10px;
}
.login-item div{
    text-align: left;
}

.image.amo{
    background-color: #4a4949;
    background-image: url("../../../image/amo.svg");
    background-repeat: no-repeat;
    background-size: 70px;
    background-position: 50% 50%;
}

.image.sheets{
    background-color: #e8f5d3;
    background-image: url("../../../image/sheets.png");
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 50% 50%;
}