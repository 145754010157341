.filter-select{
    margin-bottom: 10px;
}
.filter-select-inner{
    max-height: 130px;
    overflow: auto;
    max-width: 250px;
}
.filter-select-item{
    padding: 3px 3px 3px 30px;
    cursor: pointer;
    min-height: 27px;
    user-select: none;
}
.filter-select-item:hover{
    background: #d2d2d2;
}
.filter-select-item-content {
    white-space: nowrap;
    float: left;
    font-weight: 100;
    line-height: 20px;
    user-select: none;
}
.filter-select-item.option-select{
    background-image: url("../../image/check.png");
    background-repeat: no-repeat;
    background-position: left center;
}
.filter-select-button{
    margin-bottom: 10px;
}
