
.main-sidebar{
    width: 70px;
    background-color: #1b3446;
    position: fixed;
    height: 100%;
    z-index: 80;
}
.sidebar-menu{
    list-style: none;
    padding: 0;
    height: 100%;
    overflow: auto;
}
.sidebar-menu::-webkit-scrollbar { width: 0; }
.sidebar-menu { -ms-overflow-style: none; }

.treeview{
    padding-top: 15px;
    text-align: center;
    color: #c7c5c5;
    cursor: pointer;
}
.treeview a.active{
    color: #fff;
}
.treeview:hover{
    color: #fff;
}
.treeview a{
    color: inherit;
    text-decoration: none;
    font-size: 10px;
}
.treeview a:hover{
    text-decoration: none;
}
.nav-button div{
    color: inherit;
    font-size: 10px;
    line-height: 14px;
    margin-top: 5px;
}
.sidebar-menu .fa{
    color: inherit;
    font-size: 2.5em;
}
.page-container{
    width: calc(100vw - 70px);
    margin-left: 70px;
}
.container-inner{
    display: flex;
    padding: 65px 0 0 5px;
    width: 100%;
    overflow: auto;
    height: 100vh;
    flex-flow: column;
    position: relative;
}
.scroll-container{
    overflow: auto;
    height: inherit;
}
.container-inner table{
    width: calc(100% - 2px);
}
.th-number{
    min-width: 82px;
    width: 82px;
}
.container-inner table thead th {
    position: sticky;
    top: 0;
    background: #5f676b;
    text-align: center;
    border-top: 0;
    vertical-align: middle;
    color: #fff;
}

.dot-container i {
    font-size: 2em;
    color: #6d6c6c;
}

.left-border {
    border-right: 1px solid #d2d2d2!important;
}

.fixed-top-menu{
    height: 65px;
    position: fixed;
    width: calc(100% - 80px);
    padding: 0 15px;
    background-color: #fff;
    z-index: 50;
}

.modal.show{
    display: block;
}
.modal{
    overflow: auto!important;
}
.btn-move-prefix{
    padding: 0.375rem 0.68rem;
}
table p{
    margin-bottom: 0;
}

.branches-items{
    display: flex;
    margin-bottom: 15px;
    padding-right: 15px;
}

.branches-items>div{
    display: flex;
    flex-grow: 1;
}
.add-branches{
    display: block;
    width: 94%;
    margin: 15px;
}
.dropdown{
    text-align: left!important;
    font-size: 12px;
}
.click-element{
    cursor: pointer;
}

.directors-items{
    margin-bottom: 15px;
    display: flex;
}
.directors-items>div{
    width: 400px;
    margin-right: 15px;
}

.add-director{
    display: block;
    width: 455px;
    margin: 15px 0;
}
.sidebar{
    display: flex;
    flex-flow: column;
    height: 100%;
}
.sidebar-menu-container{
    flex-grow: 1;
    max-height: calc(100vh - 105px);
}

.sidebar .dropup{
    text-align: center;
}
.sidebar-control{
    left: 65px!important;
    transform: inherit!important;
}
.btn-sidebar-control{
    font-size: 2em;
    color: #c7c5c5;
}

.btn-sidebar-control:hover{
    font-size: 2em;
    color: #fff;
}

.sidebar-control label{
    margin-left: 10px
}
.sort-table{
    cursor: pointer;
}
th{
    z-index: 1;
}
.action-td{
    width: 150px;
}
.table-filter{
    padding: 15px;
    text-align: center;
    transform: translate(0px, 18px)!important;
    min-width: 215px;
}
.table-filter input{
    margin-bottom: 15px;
}
.table-filter .btn-filter{
    color: #fff;
}
.filter-select-button{
    display: flex;
}
.filter-select-button .btn, .filter-select-button input{
    font-size: 12px;
}
.filter-select-button .btn{
    flex-basis: 50%;
}
.filter-select-button .btn:first-child{
    margin-right: 3px;
}
.filter-select-button .btn:last-child{
    margin-left: 3px;
}

.th-container{
    display: flex;
}
.th-container .th-field-name{
    flex-grow: 1;
}
.th-container .filter-table{
    margin-left: 5px;
    align-self: center;
    cursor: pointer;
}
.sort-table small{
    margin-left: 5px;
}
.th-container .filter-table {
    color: #fff;
}
.th-container .filter-table.active {
    color: #22bf06;
}
.th-container .active-filter{
    display: inline-block;
    padding: 0 5px;
    background: #898787;
    border-radius: 5px;
}
.table.non-line th, .table.non-line td{
    border-top: 0!important;
}
.active-filter{
    cursor: pointer;
}
.active-filter:hover{
    background: #9c9999;
    transition: .3s;
}
::-webkit-scrollbar {
    height: 10px;
    overflow: visible;
    width: 10px;
}
::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}
::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    background-color: #dadce0;
    border-radius: 8px;
    box-shadow: none;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2);
    background-clip: padding-box;
    border: solid transparent;
    border-width: 1px;
    min-height: 28px;
    padding: 100px 0 0;
    box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
}
::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 0 4px;
}
::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
}
body, table {
    font-size: 12px;
}

.control-file-name, .control-branch{
    width: 150px;
}
.control-branch{
    margin-left: 7px;
}
.organization-button-container{
    width: 455px;
}
.btn.disabled, .btn:disabled {
    opacity: 0.35;
}
.organization-form{
    width: 455px;
    margin-left: 10px;
}
button{
    outline: none;
}

.showcase-style-handle-absolute{
    position: absolute;
    left: 10px;
    top: 30%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.showcase-style-handle-absolute span {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 25px;
    height: 25px;
}
.showcase-style-handle-absolute i {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #fff;
    top: 50%;
    position: absolute;
    opacity: 1;
    transform-origin: right;
    will-change: background-;
    color: #000;
    margin-bottom: 3px;
}

.showcase-style-handle-absolute i:first-child {
    margin-top: -6px;
}
.showcase-style-handle-absolute i:last-child {
    margin-top: 6px;
}

.td-vm{
    vertical-align: middle!important;
}
.td-tc{
    text-align: center;
}
.form-group{
    margin: 7px 0;
}
.form-group label:not(.checkbox){
    margin-bottom: 5px;
}
.checkbox{
    margin-left: 5px;
}
label{
    cursor: pointer;
}
.form-group label{
    font-size: 14px;
    font-weight: bold;
}
button.close{
    border: 0;
    background: none;
    font-size: 30px;
}
.action-td .btn{
    padding: 4px 10px;
    margin-right: 3px;
}

.fields-body{
    display: flex;
    gap: 20px;
}
.fields-body>div{
    width: 200px;

}
.fields-body>div>div{
    width: 100%;
    flex-basis: 200px;
    padding: 0;
}

.fields-body>div>div>div{
    width: 100%;
}
.users-equal{
    display: flex;
    align-items: center;
    font-size: 20px;
}


