.login-container{
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: cursive;
    background: #f3f6fa;
}
.auth-form-container{
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 10px;
    background: #91b5e1;
    flex-direction: column;
}
.login-head{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.auth-form-container label{
    font-size: 16px;
    color: #fff;
    margin: 5px 0;
}

.auth-form-container .btn-block{
    margin-top: 15px;
    text-align: right;
}
