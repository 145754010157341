.user-form .form-control{
    max-width: 300px;
}
.user-input{
    width: 260px;
}
.user-label{
    width: 156px;
    padding: 0;
    vertical-align: middle;
}

.user-label label {
    margin-bottom: 0;
}
.user-input .form-group{
    margin-bottom: 0;
}

.user-edit-photo{
    width: 200px;
}
.user-edit-photo img{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.user-edit-photo .custom-input-file{
    position: relative;
    top: -30px;
    left: 64px;
}
.user-edit-photo .input-user-photo{
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
.user-edit-photo label{
    cursor: pointer;
    background: #d2d2d2;
    padding: 5px;
    border-radius: 50%;
}
.user-error .form-group{
    margin-bottom: 0;
    margin-left: 10px;
}
.user-error {
    vertical-align: middle;
}
.error-field{
    color: red;
}
