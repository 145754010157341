.select-date{
    display: flex;
}
.select-date>input{
    max-width: 70px;
    padding: 6px;
    margin-right: 3px;
}
td.check, td.action{
    text-align: center;
    vertical-align: middle;
}
