.user-photo{
    width: 70px;
    height: 70px;
    text-align: center;
    z-index: 90;
    cursor: pointer;
    position: relative;
    background: #1b3446;
    display: flex;
    align-items: center;
}
.user-photo img{
    max-width: 100%;
    height: auto;
    background-color: #1b3446;
}
.user-bar{
    position: absolute;
    width: 350px;
    display: flex;
    top: 0;
    left: 0;
    height: 65px;
    margin-left: 70px;
    background-color: #162b3a;
    color: #fff;
    z-index: 85;
    -webkit-transition: -webkit-transform .3s .3s;
    transition: transform .3s .3s;
    transition: transform .3s .3s,-webkit-transform .3s .3s;
    -webkit-transform: translateX(-452px);
    transform: translateX(-452px);
 }
.user-info{
    width: 210px;
    text-align: center;
}
.user-bar.open{
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.user-profile a, .user-logout{
    width: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    flex-direction: column;
    color: #c7c5c5;
    line-height: 20px;
    height: 65px;
    text-decoration: none;
}
.user-profile a:hover{
    text-decoration: none;
}
.user-profile .fa, .user-logout .fa{
    font-size: 1.5em;
    color: inherit;
}
.user-profile{
    background-color: #1b3446;
}
.default-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.4);
    visibility: hidden;
    z-index: 1;
    opacity: 0;
}

.default-overlay.visible {
    -webkit-animation: fadeIn-fixed .2s ease both;
    animation: fadeIn-fixed .2s ease both;
    visibility: visible;
    opacity: 1;
}

#left-menu-overlay {
    left: 70px;
    width: 100vw;
    cursor: default;
}
