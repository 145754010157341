.conf-item{
    padding: 5px;
}
.ci-header{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}
.users-select{
    display:flex;
    flex-wrap: wrap;
}
.users-select>div{
    flex-basis: calc(20% - 6px);
    padding: 5px 5px 5px 25px;
    margin: 3px 3px;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
}

.conf-item .active{
    background-image: url("../../image/check.png");
    background-repeat: no-repeat;
    background-position: left center;

}
.change-item{
    opacity: .3;
}
.change-item.active{
    opacity: 1;
}
.red{
    color: red
}
th.time{
    width: 290px;
}
th.check{
    width: 90px;
}
th.action{
    width: 90px;
}
.add-button .btn{
    margin-top: 10px;
}
.delay th{
    padding: 5px 3px;
    font-size: 14px;
}
.delay tfoot td{
    border-bottom: 0;
}
.loader-container{
    width: 100%;
    height: 100px;
    position: relative;
}
.cflogin-container{
    display: flex;
    flex-wrap: wrap;
}
.cflogin-container>div{
    flex-basis: 50%;
    padding: 10px 20px;
}
.time-start{
    display: flex;
    align-items: center;
}
.time-start label{
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
}
.time-start input{
    width: 80px;
    margin-right: 10px;
}